import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-gear-icon',
    standalone: true,
    imports: [CommonModule],
    template: `
        <svg
            fill="currentColor"
            height="100%"
            viewBox="0 0 18 18"
            width="100%"
            xmlns="http://www.w3.org/2000/svg">
            <path
                clip-rule="evenodd"
                d="M8.30851 1.6875C7.62108 1.6875 7.03441 2.18449 6.9214 2.86256L6.7874 3.66655C6.77229 3.75723 6.70165 3.86235 6.56492 3.9281C6.30688 4.05219 6.05985 4.19535 5.82564 4.3557C5.70078 4.4412 5.57453 4.4499 5.48836 4.41761L4.72397 4.13125C4.08023 3.89008 3.35649 4.14966 3.01278 4.745L2.32129 5.94269C1.97758 6.53802 2.11464 7.29459 2.64537 7.7315L3.27576 8.25046C3.34673 8.30888 3.40231 8.42237 3.39099 8.57304C3.38039 8.71407 3.375 8.85646 3.375 9C3.375 9.14359 3.38039 9.28603 3.391 9.42712C3.40233 9.5778 3.34675 9.69129 3.27578 9.74971L2.64537 10.2687C2.11464 10.7056 1.97758 11.4622 2.32129 12.0575L3.01278 13.2552C3.35649 13.8505 4.08023 14.1101 4.72397 13.8689L5.48853 13.5825C5.5747 13.5502 5.70094 13.5589 5.8258 13.6444C6.05996 13.8047 6.30694 13.9478 6.56492 14.0719C6.70165 14.1377 6.77229 14.2428 6.7874 14.3335L6.9214 15.1374C7.03441 15.8155 7.62108 16.3125 8.30851 16.3125H9.69149C10.3789 16.3125 10.9656 15.8155 11.0786 15.1374L11.2126 14.3335C11.2277 14.2428 11.2983 14.1377 11.4351 14.0719C11.6931 13.9478 11.9402 13.8047 12.1744 13.6443C12.2992 13.5588 12.4255 13.5501 12.5116 13.5824L13.276 13.8687C13.9198 14.1099 14.6435 13.8503 14.9872 13.255L15.6787 12.0573C16.0224 11.462 15.8854 10.7054 15.3546 10.2685L14.7242 9.74954C14.6533 9.69112 14.5977 9.57763 14.609 9.42696C14.6196 9.28593 14.625 9.14354 14.625 9C14.625 8.85641 14.6196 8.71397 14.609 8.57288C14.5977 8.4222 14.6533 8.30871 14.7242 8.25029L15.3546 7.73131C15.8854 7.2944 16.0224 6.53784 15.6787 5.9425L14.9872 4.74481C14.6435 4.14948 13.9198 3.8899 13.276 4.13107L12.5115 4.4175C12.4253 4.44978 12.2991 4.44108 12.1742 4.35559C11.94 4.19528 11.6931 4.05216 11.4351 3.9281C11.2983 3.86235 11.2277 3.75723 11.2126 3.66655L11.0786 2.86256C10.9656 2.18449 10.3789 1.6875 9.69149 1.6875H8.30851ZM9.00009 11.8125C10.5534 11.8125 11.8126 10.5533 11.8126 9C11.8126 7.4467 10.5534 6.1875 9.00009 6.1875C7.44679 6.1875 6.18759 7.4467 6.18759 9C6.18759 10.5533 7.44679 11.8125 9.00009 11.8125Z"
                fill-rule="evenodd" />
        </svg>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GearIconComponent {}
