import { Routes } from '@angular/router';
import { PIPELINE_ROUTES } from '@features/audience/pipelines/pipelines.routes';
import { AUDIENCE_SLUGS, CONTACTS_SLUGS, SEGMENTS_SLUGS } from './routes.types';

export const SEGMENTS_ROUTES: Routes = [
    {
        path: SEGMENTS_SLUGS.table,
        title: 'Segments',
        loadComponent: () =>
            import('@features/audience/segments/segments.component').then(m => m.SegmentsComponent),
    },
    { path: '**', redirectTo: SEGMENTS_SLUGS.table },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: AUDIENCE_SLUGS.segments,
    },
] as const;

export const CONTACTS_ROUTES: Routes = [
    {
        path: CONTACTS_SLUGS.table,
        title: 'Overview',
        loadComponent: () =>
            import(
                '@features/audience/contacts/contacts-overview/contacts-overview.component'
            ).then(m => m.ContactsOverviewComponent),
    },
    {
        path: CONTACTS_SLUGS.profile,
        title: 'Profile',
        loadComponent: () =>
            import('@features/audience/contacts/contact-profile/contact-profile.component').then(
                m => m.ContactProfileComponent
            ),
    },
    {
        path: CONTACTS_SLUGS.bulkImporter,
        title: 'Bulk Importer',
        loadComponent: () =>
            import(
                '@features/audience/contacts/contacts-bulk-importer/contacts-bulk-importer.component'
            ).then(m => m.ContactsBulkImporterComponent),
    },
    { path: '**', redirectTo: CONTACTS_SLUGS.table },
] as const;

export const AUDIENCE_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: AUDIENCE_SLUGS.contacts,
    },
    {
        path: AUDIENCE_SLUGS.contacts,
        children: CONTACTS_ROUTES,
    },
    {
        path: AUDIENCE_SLUGS.segments,
        children: SEGMENTS_ROUTES,
    },
    {
        path: AUDIENCE_SLUGS.pipelines,
        loadComponent: () =>
            import('@features/audience/pipelines/pipelines.component').then(
                m => m.PipelinesComponent
            ),
        children: PIPELINE_ROUTES,
    },
    {
        path: '**',
        redirectTo: AUDIENCE_SLUGS.contacts,
    },
] as const;
