import { Routes } from '@angular/router';
import { privatePagesGuard } from '@core/guards/auth/private-pages.guard';
import { metaReconnectGuard } from '@core/guards/sync/meta-reconnect.guard';
import { MainContentComponent } from '@core/layout/main-content/main-content.component';
import { PageNotFoundComponent } from '@features/page-not-found.component';
import { APP_SLUGS } from '@routes/routes.types';
import { environment } from 'src/environments/environment';
import { AUTH_ROUTES } from './auth.routes';
import { MAIN_ROUTES } from './main.routes';

export const APP_ROUTES: Routes = [
    /* PUBLIC */
    {
        path: APP_SLUGS.AUTH,
        children: AUTH_ROUTES,
    },
    {
        path: APP_SLUGS.FROM_SHOPIFY,
        loadComponent: () =>
            import('../features/from-shopify.component').then(m => m.FromShopifyComponent),
    },

    /* PRIVATE */
    {
        path: APP_SLUGS.MAIN,
        canActivate: [privatePagesGuard, metaReconnectGuard],
        component: MainContentComponent,
        children: MAIN_ROUTES,
    },

    /* MISC */
    { path: '', pathMatch: 'full', redirectTo: environment.front.home },
    { path: APP_SLUGS.NOT_FOUND, title: 'Page not found', component: PageNotFoundComponent },
    { path: '**', redirectTo: 'not-found' },
];
