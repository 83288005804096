import { Routes } from '@angular/router';
import { WITHOUT_GROWTH_PLAN_ROUTE } from '@features/settings/billing/billing.routes';
import { AUTOMATION_SLUGS, FLOWS_SLUGS, TEMPLATES_SLUGS } from './routes.types';

export const FLOWS_ROUTES: Routes = [
    {
        path: FLOWS_SLUGS.table,
        title: 'Flows',
        loadComponent: () =>
            import('@features/automation/flows/flow-table/flow-table.component').then(
                m => m.FlowTableComponent
            ),
    },
    {
        path: FLOWS_SLUGS.editor,
        title: 'Flow Editor',
        loadComponent: () =>
            import('@features/automation/flows/flow-editor/flow-editor.component').then(
                m => m.FlowEditorComponent
            ),
    },
    {
        path: FLOWS_SLUGS.init,
        title: 'Flow Init',
        loadComponent: () =>
            import('@features/automation/flows/flow-initialize/flow-initialize.component').then(
                m => m.FlowInitializeComponent
            ),
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: FLOWS_SLUGS.table,
    },
] as const;

export const TEMPLATES_ROUTES: Routes = [
    {
        path: TEMPLATES_SLUGS.table,
        title: 'Preset Messages',
        loadComponent: () =>
            import(
                '@features/automation/automation-templates/templates-table/templates-table.component'
            ).then(m => m.TemplatesTableComponent),
    },
    {
        path: TEMPLATES_SLUGS.form,
        title: 'Template form',
        loadComponent: () =>
            import(
                '@features/automation/automation-templates/templates-form-content/templates-form-content.component'
            ).then(m => m.TemplatesFormContentComponent),
    },
    {
        path: TEMPLATES_SLUGS.wizard,
        title: 'Template wizard',
        loadComponent: () =>
            import(
                '@features/automation/automation-templates/templates-form-wizard/templates-form-wizard.component'
            ).then(m => m.TemplatesFormWizardComponent),
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: FLOWS_SLUGS.table,
    },
] as const;

export const AUTOMATION_ROUTES: Routes = [
    {
        path: AUTOMATION_SLUGS.flows,
        children: FLOWS_ROUTES,
    },
    {
        path: AUTOMATION_SLUGS.templates,
        children: TEMPLATES_ROUTES,
    },
    WITHOUT_GROWTH_PLAN_ROUTE,
    {
        path: '',
        pathMatch: 'full',
        redirectTo: AUTOMATION_SLUGS.flows,
    },
    {
        path: '**',
        redirectTo: AUTOMATION_SLUGS.flows,
    },
] as const;
