import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-insights-icon',
    standalone: true,
    imports: [CommonModule],
    template: `
        <svg
            fill="currentColor"
            height="100%"
            viewBox="0 0 18 19"
            width="100%"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.7812 2.42627C13.0046 2.42627 12.375 3.05587 12.375 3.83252V15.645C12.375 16.4217 13.0046 17.0513 13.7812 17.0513H14.3438C15.1204 17.0513 15.75 16.4217 15.75 15.645V3.83252C15.75 3.05587 15.1204 2.42627 14.3438 2.42627H13.7812Z" />
            <path
                d="M7.3125 7.20752C7.3125 6.43087 7.9421 5.80127 8.71875 5.80127H9.28125C10.0579 5.80127 10.6875 6.43087 10.6875 7.20752V15.645C10.6875 16.4217 10.0579 17.0513 9.28125 17.0513H8.71875C7.9421 17.0513 7.3125 16.4217 7.3125 15.645V7.20752Z" />
            <path
                d="M2.25 10.5825C2.25 9.80587 2.8796 9.17627 3.65625 9.17627H4.21875C4.9954 9.17627 5.625 9.80587 5.625 10.5825V15.645C5.625 16.4217 4.9954 17.0513 4.21875 17.0513H3.65625C2.8796 17.0513 2.25 16.4217 2.25 15.645V10.5825Z" />
        </svg>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsIconComponent {}
