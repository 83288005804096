@if (growthPlans().length > 1) {
    <app-modal-bg (modalClick)="onClose.emit()">
        <div class="z-30 flex flex-col">
            <header
                class="card-wrapper flex justify-between rounded-b-none pb-[19px] pl-[19px] pr-[23px] pt-[21px]">
                <div>
                    <h4 class="font-medium text-twTextPrimary">{{ TEXT.billing.upgradeNow }}</h4>
                    <h5 class="text-twGrayDark">{{ TEXT.billing.unlockMoreFeatures }}</h5>
                </div>
                <button
                    class="flex items-center gap-2 text-twGrayDark"
                    (click)="toggleApplyDiscount()">
                    <p>
                        {{ TEXT.discount.first }}
                        <span class="font-medium">{{ TEXT.discount.second }}</span>
                    </p>
                    <app-switch [isOn]="applyDiscount()" />
                </button>
            </header>

            <app-pricing-plan-card
                [isInModal]="true"
                [plans]="growthPlans()"
                [recurring]="recurring()"
                [topRounded]="false" />
        </div>
    </app-modal-bg>
}
