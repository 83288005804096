import { inject } from '@angular/core';
import { FbAccountService } from '@core/services/admin/fb-account.service';
import { InstagramService } from '@core/services/admin/instagram.service';
import { ToastrService } from 'ngx-toastr';

export const inboxReconnectGuard = () => {
    const igS = inject(InstagramService);
    const fbS = inject(FbAccountService);
    const toastr = inject(ToastrService);

    if (igS.igNeedsReconnect() || fbS.needsReconnect()) {
        toastr.error('Required to continue using the app', 'Please reconnect your Meta account');
        return false;
    }
    return true;
};
