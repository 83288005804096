import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import TEXT from '@content/settings.en.json';
import { BtnSimpleComponent } from '@shared/components/btn-simple/btn-simple.component';
import { ModalBgComponent } from '@shared/components/modal-bg/modal-bg.component';
import {
    ModalCardComponent,
    ModalContent,
} from '@shared/components/modal-card/modal-card.component';
@Component({
    selector: 'app-upgraded-growth-plan-modal',
    standalone: true,
    imports: [ModalBgComponent, ModalCardComponent, BtnSimpleComponent],
    templateUrl: './upgraded-growth-plan-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradedGrowthPlanModalComponent {
    readonly TEXT = TEXT;
    readonly imgPreHeader = 'assets/settings/growth-plan.png';
    readonly header: ModalContent = {
        title: TEXT.upgradedGrowthPlan.title,
    };
    readonly onClose = output<void>();
}
