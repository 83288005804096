<div #spacer [ngClass]="{ hidden: isSideBarOpen() }" class="w-16 min-w-[68px]"></div>

<aside
    [ngClass]="{
        'w-52': isSideBarOpen(),
        'absolute w-[68px] hover:w-52': !isSideBarOpen(),
    }"
    class="group bottom-0 top-0 z-50 flex flex-col justify-between overflow-hidden border-r-[1px] bg-twComponentBackground text-twGrayDark transition-[width]"
    aria-label="Sidebar">
    <div class="flex flex-col">
        <header
            [ngClass]="{
                'ml-2': isSideBarOpen(),
                'group-hover:ml-2': !isSideBarOpen(),
            }"
            class="ml-0 flex items-end gap-5 px-3 pb-20 pt-8 transition-[margin_left]">
            <img
                ngSrc="assets/twinny-logos/twinny-logo-only.png"
                class="min-w-[40px] max-w-[40px]"
                alt=""
                height="108"
                priority
                width="144" />
            <div
                [ngClass]="{
                    'opacity-100': isSideBarOpen(),
                    'group-hover:opacity-100': !isSideBarOpen(),
                }"
                class="mb-[-4px] h-8 min-w-[100px] bg-[url('assets/twinny-logos/twinny-title-only.png')] bg-contain bg-center bg-no-repeat opacity-0 transition-[opacity]"></div>
        </header>

        <menu class="flex flex-col">
            @for (opt of OPTS; track opt.link) {
                <ul>
                    <a
                        class="group relative flex w-full items-center justify-between py-2"
                        routerLinkActive="active"
                        [routerLink]="opt.link">
                        <div class="flex items-center">
                            <div
                                class="ml-6 mr-3 flex size-[18px] items-center justify-center text-twGray group-[.active]:text-twElectricPurple">
                                <ng-container [ngComponentOutlet]="opt.icon" />
                            </div>
                            <span
                                [ngClass]="{
                                    'opacity-100': isSideBarOpen(),
                                    'group-hover:opacity-100': !isSideBarOpen(),
                                }"
                                class="link-text whitespace-nowrap opacity-0 transition-[width_opacity]">
                                {{ opt.title }}
                            </span>
                        </div>
                        <app-active-route-marker
                            class="absolute bottom-0 right-0 top-0 hidden group-[.active]:block" />
                    </a>
                </ul>
            }
        </menu>
    </div>

    <div class="relative flex max-w-[100%] flex-col gap-5 pb-9">
        <a
            class="group relative flex w-full items-center justify-between py-2"
            routerLinkActive="active"
            [routerLink]="SETTINGS_OPT.link">
            <div class="flex items-center">
                <div
                    class="ml-6 mr-3 flex size-[18px] items-center justify-center text-twGray group-[.active]:text-twElectricPurple">
                    <ng-container [ngComponentOutlet]="SETTINGS_OPT.icon" />
                </div>
                <span
                    [ngClass]="{
                        'opacity-100': isSideBarOpen(),
                        'group-hover:opacity-100': !isSideBarOpen(),
                    }"
                    class="link-text whitespace-nowrap opacity-0 transition-[width_opacity]">
                    {{ SETTINGS_OPT.title }}
                </span>
            </div>
            <app-active-route-marker
                class="absolute bottom-0 right-0 top-0 hidden group-[.active]:block" />
        </a>

        <button class="flex max-w-[100%] items-center" (click)="handleAvatarClick($event)">
            <app-avatar class="ml-[1.37rem] mr-3" size="sm" [name]="name()" [src]="profileImg()" />
            <div
                [ngClass]="{
                    'opacity-100': isSideBarOpen(),
                    'group-hover:opacity-100': !isSideBarOpen(),
                }"
                class="min-w-[136px] opacity-0 transition-[width_opacity]">
                <div
                    class="overflow-hidden whitespace-normal break-all text-start text-twTextPrimary">
                    {{ (accountS.account()?.account_name | titlecase) || '' }}
                </div>
            </div>
        </button>
    </div>
</aside>

<app-avatar-menu
    class="absolute bottom-0 left-0 z-[51] mt-[40px]"
    [isMenuOpen]="isMenuOpen()"
    (toggleMenu)="handleAvatarClick($event)" />
