import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { SubscriptionData } from '@core/models/admin/admin-account.types';
import { WarningHexComponent } from '@shared/components/icons/warning-hex/warning-hex.component';
import { SUB_STATUS } from '@core/models/admin/admin-account.types';
import { Router } from '@angular/router';
import { CheckoutTypeParam } from '@features/settings/config/stripe.config';

@Component({
    selector: 'app-sticky-bar-warning',
    standalone: true,
    imports: [CommonModule, WarningHexComponent],
    templateUrl: './sticky-bar-warning.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StickyBarWarningComponent {
    private readonly router = inject(Router);

    @Input() sub?: SubscriptionData;

    texts = {
        paymentIssue: 'Your account has been suspended due to an issue with the payment method',
        expiredTrial:
            "The trial period has ended. To continue using the application, you'll need to enter a payment method",
        cancelled: 'Your subscription is canceled. To use the app please upgrade your subscription',
    };

    btnTexts = {
        paymentIssue: 'Update payment method',
        expiredTrial: 'Upgrade plan',
        cancelled: 'Upgrade',
    };

    get content() {
        if (
            this.sub?.status === SUB_STATUS.INCOMPLETE ||
            this.sub?.status === SUB_STATUS.INCOMPLETE_EXPIRED ||
            this.sub?.status === SUB_STATUS.PAST_DUE ||
            this.sub?.status === SUB_STATUS.UNPAID
        )
            return {
                text: this.texts.paymentIssue,
                btnText: this.btnTexts.paymentIssue,
                params: { action: 'edit' },
            };

        if (this.sub?.status === SUB_STATUS.CANCELED)
            return {
                text: this.texts.cancelled,
                btnText: this.btnTexts.cancelled,
                params: { action: 'subscribe' },
            };

        if (this.sub?.status === SUB_STATUS.TRIAL && this.sub?.days_until_due <= 0)
            return {
                text: this.texts.expiredTrial,
                btnText: this.btnTexts.expiredTrial,
                params: { action: 'edit' },
            };
    }

    handleClick() {
        const params = { queryParams: this.content?.params || {} };

        this.router.navigate(['/main/account/checkout'], params);
    }
}
