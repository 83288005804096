import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { SUB_STATUS } from '@core/models/admin/admin-account.types';
import { AccountService } from '@core/services/admin/account.service';
import { SubscriptionService } from '@core/services/admin/subscription.service';
import { BillingService } from '@features/settings/billing/services/billing.service';
import { SETTINGS_SLUGS } from '@features/settings/settings.routes';
import { StickyBarWarningComponent } from '@shared/components/sticky-bar-warning/sticky-bar-warning.component';
import { TrialWarningComponent } from '../../../features/settings/billing/components/trial-warning/trial-warning.component';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
    selector: 'app-main-content',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        SidebarComponent,
        StickyBarWarningComponent,
        TrialWarningComponent,
    ],
    templateUrl: './main-content.component.html',
    styles: [':host {display: flex; flex-direction: column; flex: 1 1 0; overflow: hidden}'],
})
export class MainContentComponent implements OnInit {
    readonly accS = inject(AccountService);
    readonly subS = inject(SubscriptionService);
    private readonly billingS = inject(BillingService);
    private readonly router = inject(Router);

    private readonly isInCheckout = signal(false);

    readonly showTrialWarning = computed(() => this.isTrial() && !this.isInCheckout());
    private readonly isTrial = computed(() => this.billingS.subStatus() === SUB_STATUS.TRIAL);

    ngOnInit() {
        this.isInCheckout.set(this.router.url.includes(SETTINGS_SLUGS.checkout));
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.isInCheckout.set(event.url.includes(SETTINGS_SLUGS.checkout));
            }
        });
    }
}
