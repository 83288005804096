<button
    class="flex h-full w-full cursor-default items-center justify-center bg-twGrayLightest bg-opacity-90"
    (click)="handleClose()">
    <div
        class="relative flex h-[552px] w-[472px] flex-col rounded bg-twLightPurple pt-[94px] text-center opacity-100">
        <button class="absolute right-5 top-8 flex h-4 w-4">
            <app-cross-icon class="h-full w-full text-twGray" />
        </button>

        <div class="mx-auto mb-[48px] h-[198px] w-[198px]">
            <img alt="" src="assets/bg-imgs/cancel-sub-success.png" />
        </div>
        <h2 class="text-2xl font-medium text-twElectricPurple">Your plan has been cancelled</h2>
        <p class="px-[84px] pt-2 text-twGrayDark">
            We regret that you are no longer part of Twinny. We hope to see you again here very
            soon.
        </p>
    </div>
</button>
