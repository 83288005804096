import { effect, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FbAccountService } from '@core/services/admin/fb-account.service';
import { InstagramService } from '@core/services/admin/instagram.service';
import { APP_SLUGS, MAIN_SLUGS } from '@routes/routes.types';

export const metaReconnectGuard: CanActivateFn = (route, state) => {
    const router = inject(Router);
    const igS = inject(InstagramService);
    const fbAccountS = inject(FbAccountService);

    effect(() => {
        const onlyPageAvailable = `${APP_SLUGS.MAIN}/${MAIN_SLUGS.SMART_INBOX}`;
        if (
            (igS.igNeedsReconnect() || fbAccountS.needsReconnect()) &&
            !state.url.includes(onlyPageAvailable)
        ) {
            router.navigateByUrl(onlyPageAvailable);
        }
    });

    return true;
};
