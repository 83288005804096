import { Routes } from '@angular/router';
import { inboxReconnectGuard } from '@core/guards/inbox-reconnect.guard';
import { INSIGHTS_ROUTE } from '@features/insights/insights.routes';
import { PageNotFoundComponent } from '@features/page-not-found.component';
import { subscriptionLevelGuard } from '@features/settings/billing/guards/subscription-level.guard';
import { subscriptionGuard } from '@features/settings/billing/guards/subscription.guard';
import { SETTINGS_ROUTES, SETTINGS_SLUGS } from '../features/settings/settings.routes';
import { AUDIENCE_ROUTES } from './audience.routes';
import { AUTOMATION_ROUTES } from './automation.routes';
import { CAMPAIGNS_ROUTES } from './campaigns.routes';
import { PAYMENT_ROUTES } from './payment.routes';
import { PRODUCT_ROUTES } from './products.routes';
import { MAIN_SLUGS } from './routes.types';

export const MAIN_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: MAIN_SLUGS.DASHBOARD,
    },
    {
        path: MAIN_SLUGS.DASHBOARD,
        title: 'Dashboard',
        loadComponent: () =>
            import('@features/dashboard/dashboard.component').then(m => m.DashboardComponent),
        canActivate: [subscriptionGuard],
    },
    {
        path: MAIN_SLUGS.PRODUCTS,
        loadComponent: () =>
            import('@features/products/products.component').then(m => m.ProductsComponent),
        children: PRODUCT_ROUTES,
        canActivate: [subscriptionGuard],
    },
    {
        path: MAIN_SLUGS.SETTINGS,
        title: 'Settings',
        children: SETTINGS_ROUTES,
    },
    {
        path: SETTINGS_SLUGS.checkout,
        title: 'Checkout',
        loadComponent: () =>
            import('@features/settings/checkout-page/checkout.component').then(
                m => m.CheckoutComponent
            ),
    },
    {
        path: MAIN_SLUGS.SMART_INBOX,
        title: 'Inbox',
        canDeactivate: [inboxReconnectGuard],
        loadComponent: () => import('@features/inbox/inbox.component').then(m => m.InboxComponent),
        canActivate: [subscriptionGuard],
    },
    {
        path: MAIN_SLUGS.AUDIENCE,
        loadComponent: () =>
            import('@features/audience/audience.component').then(m => m.AudienceComponent),
        children: AUDIENCE_ROUTES,
        canActivate: [subscriptionGuard],
    },
    {
        path: MAIN_SLUGS.CAMPAIGNS,
        loadComponent: () =>
            import('@features/campaigns/campaigns.component').then(m => m.CampaignsComponent),
        children: CAMPAIGNS_ROUTES,
        canActivate: [subscriptionGuard, subscriptionLevelGuard],
    },
    {
        path: MAIN_SLUGS.TEMPLATES,
        loadComponent: () =>
            import('@features/templates/templates.component').then(m => m.TemplatesComponent),
        canActivate: [subscriptionGuard],
    },
    {
        path: MAIN_SLUGS.AUTOMATION,
        children: AUTOMATION_ROUTES,
        canActivate: [subscriptionGuard, subscriptionLevelGuard],
    },
    {
        path: MAIN_SLUGS.PAYMENT,
        children: PAYMENT_ROUTES,
        canActivate: [subscriptionGuard],
    },
    {
        path: MAIN_SLUGS.CUSTOMERS,
        component: PageNotFoundComponent,
    },
    {
        path: MAIN_SLUGS.LISTENING,
        title: 'Social listening',
        loadComponent: () =>
            import('@features/analysis/social-listening/social-listening.component').then(
                m => m.SocialListeningComponent
            ),
        canActivate: [subscriptionGuard],
    },
    {
        path: MAIN_SLUGS.USERS,
        title: 'Social users',
        loadComponent: () =>
            import('@features/analysis/social-users/social-users.component').then(
                m => m.SocialUsersComponent
            ),
        canActivate: [subscriptionGuard],
    },
    INSIGHTS_ROUTE,

    { path: MAIN_SLUGS.NOT_FOUND, title: 'Page not found', component: PageNotFoundComponent },
];
