import { NgClass, NgComponentOutlet, NgOptimizedImage, TitleCasePipe } from '@angular/common';
import { Component, HostListener, OnInit, computed, inject, input, signal } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { AvatarMenuComponent } from '@core/layout/avatar-menu/avatar-menu.component';
import { AccountService } from '@core/services/admin/account.service';
import { UserService } from '@core/services/admin/user.service';
import { MAIN_SLUGS } from '@routes/routes.types';
import { AvatarComponent } from '@shared/components/avatar/avatar.component';
import { filter, map } from 'rxjs';
import { ActiveRouteMarkerComponent } from './active-route-marker/active-route-marker.component';
import { SIDEBAR_OPTS, SETTINGS_OPT } from './sidebar.config';

@Component({
    selector: 'app-sidebar',
    standalone: true,
    imports: [
        NgComponentOutlet,
        NgOptimizedImage,
        NgClass,
        TitleCasePipe,
        RouterModule,
        AvatarMenuComponent,
        AvatarComponent,
        ActiveRouteMarkerComponent,
    ],
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    private readonly router = inject(Router);
    private readonly userS = inject(UserService);
    readonly accountS = inject(AccountService);

    readonly MAIN = MAIN_SLUGS;
    readonly OPTS = SIDEBAR_OPTS;
    readonly SETTINGS_OPT = SETTINGS_OPT;
    private readonly analyticsv2 = ['listening', 'users'];

    readonly isSideBarOpen = input(false);

    readonly isMenuOpen = signal(false);
    private readonly lastSlug = signal('');

    readonly isAnalytics = computed(() => this.analyticsv2.includes(this.lastSlug()));
    readonly profileImg = computed(() => this.userS.user()?.avatar);
    readonly name = computed(() => this.userS.user()?.name);

    @HostListener('document:click', ['$event']) onDocumentClick() {
        if (this.isMenuOpen) {
            this.isMenuOpen.set(false);
        }
    }

    ngOnInit() {
        this.router.events
            .pipe(
                filter((event: any) => event instanceof NavigationEnd || 'routerEvent' in event),
                map((event: any) => event?.url || event?.routerEvent!.url)
            )
            .subscribe(url => {
                const lastUrlSegment = url.split('?')[0].split('/').pop();
                this.lastSlug.set(lastUrlSegment);
            });
    }

    handleAvatarClick(event: Event) {
        event.stopPropagation();
        this.isMenuOpen.update(st => !st);
    }
}
