{
    "sidebar": {
        "dashboard": "Dashboard",
        "inbox": "Inbox",
        "products": "Products",
        "audience": "Audience",
        "campaigns": "Campaigns",
        "automation": "Automation",
        "payments": "Payments",
        "insights": "Insights",
        "settings": "Settings"
    }
}
