import { Routes } from '@angular/router';
import { WITHOUT_GROWTH_PLAN_ROUTE } from '@features/settings/billing/billing.routes';
import { CAMPAIGNS_SLUGS } from './routes.types';

export const CAMPAIGNS_ROUTES: Routes = [
    {
        path: CAMPAIGNS_SLUGS.upgrade,
        loadComponent: () =>
            import('@features/campaigns/main/upgrade-to-pro.component').then(
                m => m.UpgradeToProComponent
            ),
    },
    {
        path: CAMPAIGNS_SLUGS.start,
        loadComponent: () =>
            import('@features/campaigns/main/camp-start.component').then(m => m.CampStartComponent),
    },
    {
        path: CAMPAIGNS_SLUGS.table,
        loadComponent: () =>
            import('@features/campaigns/main/camp-table/camp-table.component').then(
                m => m.CampTableComponent
            ),
    },
    {
        path: CAMPAIGNS_SLUGS.form,
        loadComponent: () =>
            import('@features/campaigns/main/camp-form/camp-form.component').then(
                m => m.CampFormComponent
            ),
    },
    WITHOUT_GROWTH_PLAN_ROUTE,
    { path: '**', redirectTo: CAMPAIGNS_SLUGS.table },
];
