<div class="flex w-full flex-1 overflow-y-hidden">
    <app-sidebar class="relative flex" />
    <main
        class="relative flex h-full w-full flex-col overflow-auto bg-twMainBackground text-twTextPrimary">
        @if (showTrialWarning()) {
            <app-trial-warning />
        }

        <div class="flex flex-grow flex-col">
            <router-outlet />
        </div>
    </main>
</div>
